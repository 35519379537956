import { css } from 'astroturf';
import React from 'react';

const styles = css`
  .workspaceWrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }
  .workspaceWrapper .panelist {
    position: relative;
    width: auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    gap: 8px;

    & li {
      margin: 0;
      text-align: center;
      line-height: 2;
    }

    & div {
      margin-bottom: 4px;
      background: rgba(0, 70, 150, 0.1);
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.02) 0px 0px 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;

      & i {
        font-size: 0.8em;
        display: block;
        opacity: 0.8;
        font-style: normal;
      }
    }
  }
`;

const WorkspacePreview = ({ sizes }) => (
  <div className={styles.workspaceWrapper}>
    <ul className={[styles.panelist, 'panelist'].join(' ')}>
      {sizes.map((item) => (
        <li key={item.width}>
          <div
            style={{
              width: `${item.width / 5}px`,
              height: item.height !== "auto" ? `${item.height / 5}px` : `${item.width / 8}px`,
            }}
          >
            {item.name}
            <i>{!item.hideWidth ? <>{item.width}px</> : <>&nbsp;</>}</i>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default WorkspacePreview;
