import { css } from 'astroturf';
import React from 'react';
import Brow from '../components/Brow';
import CTA from '../components/CTA-small';
import WorkspacePreview from '../components/OpenerWorkspacePreview';
import SEO from '../components/SEO';
import Layout from '../layout';

const styles = css`
  .title {
    padding-top: 8rem;
    max-width: 45rem;
    margin: 0 auto;
    text-align: center;

    & .explainer {
      color: #0a1530;
      font-size: 1.75rem;
      font-weight: 500;
      margin: 0;
    }
  }

  .openerbutton {
    padding: 2rem 4rem;
    text-align: center;

    & p {
      margin-bottom: 1rem;
    }

    & a {
      position: relative;
      pointer-events: all;
      margin: 0;
      font-size: 1.5rem;
      font-weight: 700;
      color: rgba(255, 255, 255, 1);
      background: linear-gradient(135deg, #2c82ad, #67b26f);
      border-radius: 40px;
      text-align: center;
      display: inline-block;
      text-decoration: none;
      line-height: 1.5;
      transition: all 0.25s ease-in-out;
      padding: 0;
      margin-bottom: 1.5rem;
      border: 0;
      cursor: pointer;

      & span {
        position: relative;
        z-index: 1;
        padding: 0.33rem 1rem;
        content: ' ';
        display: block;
        width: calc(100%);
        height: calc(100%);
        z-index: 0;
        opacity: 1;
        background: transparent;
        transition: background 0.25s ease-in-out;
        border-radius: 40px;
      }

      &:hocus {
        text-decoration: none;
        & span {
          background: #0a1530;
        }
      }
    }
  }

  .cta {
    text-align: center;
    padding: 4rem 0 8rem;
  }

  .launcher {
    margin-top: 4rem;
    height: 10rem;
  }
`;

class Opener extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      hasError: false,
      gottenURL: false,
      paneSizes: false,
      url: false,
      showLink: false,
    };
  }

  componentDidMount() {
    const URLObj = new URL(window.location.href);

    const gottenURL = URLObj.searchParams.get('url');
    let paneSizes = false;

    try {
      if (gottenURL) {
        const polypaneURL = new URL(`polypane://${gottenURL.replace(':', '')}`);
        const paneSizesParams = URLObj.searchParams.get('panesizes');
        if (paneSizesParams) {
          polypaneURL.searchParams.set('panesizes', paneSizesParams);
        } else if (URLObj.searchParams.get('w')) {
          const widths = JSON.parse(URLObj.searchParams.get('w'));
          const heights = JSON.parse(URLObj.searchParams.get('h').replace(`,auto"`, `,"auto"`));
          const titles = JSON.parse(URLObj.searchParams.get('t'));

          paneSizes = widths.map((width, index) => ({
            width,
            height: heights[index],
            title: titles[index],
          }));

          polypaneURL.searchParams.set('panesizes', JSON.stringify(paneSizes));
        }

        if (URLObj.searchParams.get('s')) {
          polypaneURL.searchParams.set('s', URLObj.searchParams.get('s'));
        }

        window.location.href = polypaneURL.href;

        setTimeout(() => {
          this.setState({ showLink: true });
        }, 2000);

        this.setState({ url: polypaneURL.href, gottenURL, paneSizes, hasError: false });
      } else {
        this.setState({ url: '', hasError: true });
      }
    } catch (e) {
      this.setState({ url: '', hasError: true });
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Polypane Link"
          description="This URL opens a link in Polypane along with a set of panes."
          keywords={['']}
          pathname={this.props.location.pathname}
          ogFileName="link"
          ogCustomPath="/og-custom/"
          imageDescription="Polypane Link"
        />
        <div className={styles.title}>
          <Brow>🔗 Polypane Link</Brow>
          <p className={styles.explainer}>
            Click <strong>Open link</strong> on the dialog shown by your browser.
          </p>
        </div>

        {this.state.hasError ? (
          <div className={styles.openerbutton}>
            <p>
              Unfortunately there is an issue with the opener link, or none was provided. Please ask the sender for a
              new one.
            </p>
          </div>
        ) : this.state.url ? (
          <div className={styles.openerbutton}>
            <p>
              {this.state.paneSizes ? (
                <>
                  This will open <strong>{this.state.gottenURL}</strong> in Polypane with{' '}
                  {this.state.paneSizes.length === 1 ? 'this pane:' : `these ${this.state.paneSizes.length} panes:`}
                </>
              ) : null}
            </p>

            {this.state.paneSizes ? <WorkspacePreview sizes={this.state.paneSizes} /> : null}
            <div className={styles.launcher}>
              {this.state.showLink ? (
                <>
                  <p>
                    If you don’t see a dialog, click <strong>Launch Polypane</strong> below.
                  </p>
                  <a href={this.state.url}>
                    <span>Launch Polypane</span>
                  </a>
                </>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className={styles.cta}>
          <h2>Don't have Polypane installed?</h2>
          <CTA noCC buttonText="Start a trial" />
        </div>
      </Layout>
    );
  }
}

export default Opener;
